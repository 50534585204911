.pikaday-dark {
    --backgroundColor: #2d3748;
    --textColor: #f7fafc;
    --currentDateTextColor: #3182ce;
    --selectedDateBackgroundColor: #3182ce;
    --selectedDateTextColor: #f7fafc;

    --labelTextColor: #3182ce; /* eg. May 2020 */
    --weekDaysTextColor: #a0aec0; /* eg. Mo Tu We ....*/

    background-color: var(--backgroundColor);
    border-radius: 10px;
    border: 0;
    padding: 0.7rem;
    z-index: 2000;
    margin: 6px 0 0 0;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
    font-family: inherit;
}
.pikaday-dark .pika-prev {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23a0aec0'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M15 19l-7-7 7-7'%3E%3C/path%3E%3C/svg%3E");
}
.pikaday-dark .pika-next {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23a0aec0'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M9 5l7 7-7 7'%3E%3C/path%3E%3C/svg%3E");
}
.pika-prev.is-disabled,
.pika-next.is-disabled {
    cursor: default;
    opacity: 0.05;
}
.pikaday-dark .pika-label {
    color: var(--labelTextColor);
}
.pikaday-dark table th {
    color: var(--weekDaysTextColor);
}

/* remove underline from mon tue wed ... */
.pikaday-dark table th abbr {
    text-decoration: none;
    pointer-events:none;
}
.pikaday-dark table td button:hover {
    background-color: var(--selectedDateBackgroundColor);
}
.pikaday-dark table td.is-today button {
    color: var(--currentDateTextColor);
}
.pikaday-dark table td.is-selected button {
    background-color: var(--selectedDateBackgroundColor);
}
.pikaday-dark .pika-label {
    background-color: var(--backgroundColor);
}
.pikaday-dark table td button,
.pikaday-dark table td button:hover {
    color: var(--textColor);
    background-color: var(--backgroundColor);
}
.pikaday-dark table td.is-selected button,
.pikaday-dark table td.is-selected button:hover {
    color: var(--textColor);
    background-color: var(--selectedDateBackgroundColor);
}
