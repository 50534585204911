.login-with-btn {
    transition: background-color .3s, box-shadow .3s;
    padding: 12px 4px;
    border: none;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    color:rgba(255, 255, 255, 0.8);
    font-size: 14px;
    font-weight: 500;

    background-color: #0b5864;
    background-repeat: no-repeat;
    background-position: 12px 11px;
}

.login-with-btn span {
    vertical-align:middle;
}

.login-with-btn.google:before {
    display: inline-block;
    content: " ";
    height: 18px;
    width: 18px;
    background-size: 18px 18px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
    margin-right:8px;
    vertical-align:middle;
}
.login-with-btn.facebook:before {
    display: inline-block;
    content: " ";
    height: 18px;
    width: 18px;
    background-size: 18px 18px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMzY1LjEyIiBoZWlnaHQ9IjEzNjUuMTIiIHZpZXdCb3g9IjAgMCAxNDIyMiAxNDIyMiI+PGNpcmNsZSBjeD0iNzExMSIgY3k9IjcxMTIiIHI9IjcxMTEiIGZpbGw9IiMxOTc3ZjMiLz48cGF0aCBkPSJNOTg3OSA5MTY4bDMxNS0yMDU2SDgyMjJWNTc3OGMwLTU2MiAyNzUtMTExMSAxMTU5LTExMTFoODk3VjI5MTdzLTgxNC0xMzktMTU5Mi0xMzljLTE2MjQgMC0yNjg2IDk4NC0yNjg2IDI3Njd2MTU2N0g0MTk0djIwNTZoMTgwNnY0OTY5YzM2MiA1NyA3MzMgODYgMTExMSA4NnM3NDktMzAgMTExMS04NlY5MTY4eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==");
    margin-right:8px;
    vertical-align:middle;
}

.login-with-btn:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}

.login-with-btn:active {
    background-color: #eeeeee;
}

.login-with-btn:focus {
    outline: none;
    box-shadow: 
        0 -1px 0 rgba(0, 0, 0, .04),
        0 2px 4px rgba(0, 0, 0, .25),
        0 0 0 3px #c8dafc;
}

.login-with-btn:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
}