@import url('https://fonts.googleapis.com/css2?family=Barlow&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;700&display=swap');

:root {
    --vasoo-color-dark-blue: #00273a;
    --vasoo-color-dark-blue-lighter: #091d2b;
    --vasoo-color-feather-blue: #16B9D2;
    --vasoo-color-feather-blue-darker: #1097ac;
}


/* causes error in firefox
 * html:has(.uk-light),
 */
html,
body > .uk-light {
    background-color: var(--vasoo-color-dark-blue-lighter);
}

html,
body > div {
    background-color: var(--vasoo-color-dark-blue-lighter);
}

body {
    font-family: 'Barlow', sans-serif;
}

body .uk-top {
    background-color: var(--vasoo-color-dark-blue);
}

body input[type=submit] {
    font-weight: bold;
    background-color: var(--vasoo-color-feather-blue);
}

/* Chrome, Safari, Edge, Opera */
body input[type=number]::-webkit-outer-spin-button,
body input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
body input[type=number] {
  -moz-appearance: textfield;
}

input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
    /*color: #B0B0B0*/
}

body input[type=text],
body input[type=email],
body input[type=date],
body input[type=number],
body input[type=checkbox],
body input[type=password] {
    color: #000;
    background-color: #eee;
    border: 2px solid #B0B0B0;
}

body > .uk-light input[type=text],
body > .uk-light input[type=email],
body > .uk-light input[type=date],
body > .uk-light input[type=number],
body > .uk-light input[type=checkbox],
body > .uk-light input[type=password] {
    color: #fff;
    background-color: #2a3237;
    border: 1px solid #000;
}

body input.uk-form-success:focus {
    color: var(--vasoo-color-feather-blue);
    border-color: var(--vasoo-color-feather-blue);
}

body > .uk-light input.uk-form-success:focus {
    color: var(--vasoo-color-feather-blue-darker);
    border-color: var(--vasoo-color-feather-blue-darker);
}

body input.uk-form-danger,
body input.uk-form-danger:focus {
    color: #f0506e;
    border-color: #f0506e;
}

body .form-body > div {
    margin-top: 48px;
}

body .uk-icon-button {
    cursor: pointer;
    background: transparent;
    border: 0;
}
body .uk-icon-button:hover {
    background: transparent;
}

body > .uk-light input[type=submit],
body > .uk-light input[type=submit].uk-button-secondary {
    color: #fff;
    background-color: var(--vasoo-color-feather-blue-darker);
}

body > .uk-light input[type=submit]:hover,
body > .uk-light input[type=submit]:focus,
body > .uk-light input[type=submit].uk-button-secondary:hover,
body > .uk-light input[type=submit].uk-button-secondary:focus {
    background-color: var(--vasoo-color-feather-blue-darker);
}

body > .uk-light a {
    color: #01abfd;
}

body > .uk-light button.uk-icon-button > img {
    filter: invert(100%);
}

body input[type=checkbox]:focus {
    background-color: rgba(255, 255, 255, 0.15)
}
body input[type=checkbox]:checked {
    background-color: rgba(255, 255, 255, 0.15)
}
body input[type=checkbox] {
    margin-top: 1px;
    flex-shrink: 0;
    padding: 0 !important;
    height: 32px !important;
    width: 32px !important;
}
body > .uk-light input[type=checkbox]:checked {
    background-color: #2a3237 !important;
}
body > .uk-light input[type=checkbox]:focus,
body > .uk-light input[type=checkbox]:focus:checked,
body > .uk-light input[type=checkbox]:active {
    background-color: rgba(255,255,255,.15) !important;
}

body > .uk-light input[type=checkbox]:checked,
body > .uk-light input[type=checkbox]:focus:checked {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2214%22%20height%3D%2211%22%20viewBox%3D%220%200%2014%2011%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23fff%22%20points%3D%2212%201%205%207.5%202%205%201%205.5%205%2010%2013%201.5%22%20%2F%3E%0A%3C%2Fsvg%3E%0A")
}
body input[type=checkbox] + p {
    padding-left: 14px;
    margin: 0;
}

/* when content is taller than viewport, uk-height-1-1
 * styles target to fill viewport rather than wrap content
 * this override styles target to wrap whichiver is taller
 */
body .uk-height-1-1 {
    min-height:100%;
    height: unset;
}

.uk-modal-dialog {
    background: #252525;
    color: #ededed;
    .uk-modal-footer {
      background: #252525;
      border-top: 1px solid #54545440;
    }
  
    .uk-button-default {
        color: #ededed;
        border: 1px solid #54545440;
    }
  
    .uk-modal-header {
      background: #252525;
      border-bottom: 1px solid #54545440;
    }
    .uk-button-primary {
        transition: background-color .3s, box-shadow .3s;
        border: none;
        box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
        color:rgba(255, 255, 255, 0.8);
        font-weight: 300;
    
        background-color: #0b5864;
        background-repeat: no-repeat;
        background-position: 12px 11px;
        cursor: pointer;
    }
  }
  