:root {
    --gear-color-gray: rgba(255, 255, 255, 0.7);
    --gear-color-gray-transparent: rgba(255, 255, 255, 0);
    --gear-animation: rot 60s infinite linear;
}

.layout-gear {
    display: none;
}

.app-isdev-true .layout-gear {
    display: block;
}

.layout-gear {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .4;
    background-color: rgba(0, 0, 0, 0.0);
    pointer-events: none;
}

.layout-gear .gear {
    position: absolute;
    top: -80px;
    left: -80px;
}

.layout-gear .gear {
    display: inline-block;
    position: relative;
    border-radius: 50%;
    -webkit-filter: drop-shadow(1px 1px 3px silver);
    background-position: 50% 50%;
    vertical-align: middle;
    animation: var(--gear-animation);
}
.layout-gear .gear:before, .layout-gear .gear:after {
    position: absolute;
    border-radius: 50%;
    content: '';
}

.layout-gear .gear {
    width: 7.05em;
    height: 7.05em;
    border: dotted .4em var(--gear-color-gray);
    box-shadow: inset 0 0 0 .5em var(--gear-color-gray);
    background:
      linear-gradient(
        var(--gear-color-gray-transparent) 47.5%,
        var(--gear-color-gray) 47.5%,
        var(--gear-color-gray) 52.5%,
        var(--gear-color-gray-transparent) 52.5%),
      linear-gradient(
        to right,
        var(--gear-color-gray-transparent) 47.5%,
        var(--gear-color-gray) 47.5%,
	var(--gear-color-gray) 52.5%,
        var(--gear-color-gray-transparent) 52.5%);
    background-repeat: no-repeat;
    background-size: 87.5% 87.5%;
}

@keyframes rot { to { transform: rotate(360deg); } }
